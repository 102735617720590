import React, { forwardRef } from 'react';

import s from './CarouselButton.scss';

interface IProps {
  direction: 'previous' | 'next';
  disabled?: boolean;
  onClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
}

export const CarouselButton = forwardRef(({ direction, disabled, onClick, onMouseEnter, onMouseLeave, className }: IProps, ref: any) => {

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={s('carouselButton', className, direction, { disabled })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={ref}
    >
      <span className={s.carouselButton__inner}>
        <span className={s.carouselButton__arrowLine} />
        <span className={s.carouselButton__arrowBody}>
          <svg width="10" height="17" className={s.carouselButton__svg}>
            <title>{direction}</title>
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="square"
              strokeWidth="2"
              d="M8.5 1.5L1.5 9l7 7.5"
            />
          </svg>
        </span>
      </span>
    </button>
  );
});

CarouselButton.defaultProps = {
  direction: 'next',
};
