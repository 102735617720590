import React, { cloneElement, useRef, useEffect, useState } from 'react';

import { Caption } from 'components/caption/Caption';
import { HeroEmbed } from 'components/hero-embed/HeroEmbed';
import { PlayButton } from 'components/play-button/PlayButton';
import { Row } from 'components/layout/Row';

import s from './VideoFullscreen.scss';

interface IProps {
  image: React.ReactElement<any>;
  imageCaption?: string;
  imageCredits?: string;
  poster?: string;
  video?: string;
  youtubeID?: string;
  kicker?: string | React.ReactElement<any>;
  heading?: string;
}

export const VideoFullscreen = ({
  image,
  poster,
  imageCaption,
  imageCredits,
  video,
  youtubeID, // modal video
  kicker,
  heading,
 }: IProps) => {

  const heroNode = useRef<HTMLDivElement>(null);
  const contentNode = useRef<HTMLDivElement>(null);
  const captionNode = useRef<HTMLDivElement>(null);
  const videoNode = useRef<HTMLVideoElement>(null);
  const [ready, updateReady] = useState(false);

  // if video modal, need to set some states
  const [videoPlaying, setVideoPlaying] = useState(false);

  const onLoadedData = () => {
    if (!videoNode.current) { return; }
    videoNode.current.play();
  };

  useEffect(() => {
    if (!videoNode.current) { return; }

    videoNode.current.addEventListener('loadedmetadata', onLoadedData);
    videoNode.current.addEventListener('playing', () => { updateReady(true); });
    return () => {
      if (videoNode.current) {
        videoNode.current.removeEventListener('loadedmetadata', onLoadedData);
        videoNode.current.removeEventListener('playing', () => { updateReady(true); });
      }
    };
  }, []);

  return (
    <div className={s('videoFullscreen', { ready })}>
      <div className={s.videoFullscreen__inner}>
        <article className={s.videoFullscreen__node} ref={heroNode}>
          <div className={s.videoFullscreen__nodeWrap}>
            {image && (
              <div className={s.videoFullscreen__imageWrap}>
                {cloneElement(image, {
                  className: s.videoFullscreen__image,
                  alt: imageCaption || '',
                })}
              </div>
            )}
            {video && (
              <div className={s.videoFullscreen__videoWrap}>
                <video
                  className={s.videoFullscreen__video}
                  muted
                  playsInline
                  src={video}
                  loop
                  ref={videoNode}
                  poster={poster}
                />
              </div>
            )}
            <div className={s.videoFullscreen__contentContainer}>
              <Row>
                <div className={s.videoFullscreen__content} ref={contentNode}>
                  <div className={s.videoFullscreen__contentInner}>
                    {kicker && (<div className={s.videoFullscreen__kicker}>{kicker}</div>)}
                    <h1 className={s.videoFullscreen__heading}>
                      {heading}
                    </h1>
                    {youtubeID && (
                      <div className={s.videoFullscreen__playButton}>
                        <PlayButton onClick={() => { setVideoPlaying(true); }} />
                      </div>
                    )}
                  </div>
                </div>
              </Row>
            </div>
            {(imageCaption || imageCredits) && (
              <div className={s.videoFullscreen__caption} ref={captionNode}>
                <Caption
                  position="inside"
                  align="right"
                  caption={imageCaption}
                  credits={imageCredits}
                />
              </div>
            )}
          </div>
      </article>
      {youtubeID && (
        <HeroEmbed play={setVideoPlaying} playing={videoPlaying} youtubeID={youtubeID} />
      )}
      </div>
    </div>
  );
};
