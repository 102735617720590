import React, {cloneElement} from 'react';
import { Link } from 'components/link/Link';

import s from './UtilityCard.scss';

interface IProps {
  heading: string;
  category?: string;
  children?: React.ReactElement<any>; // image
  active?: boolean;
  link?: string;
  map?: React.ReactElement<any>; // location map
  headingSize?: 'default' | 'small'; // TODO: maybe do this better - could there be something else to listen for?
}

export const UtilityCard = ({ active, children, heading, category, link, map, headingSize }: IProps) => (
  <div className={s('utilityCard', { active, hasMap: map })}>
    <div className={s.utilityCard__imageWrap}>
      {children &&
        cloneElement(children as React.ReactElement<any>, {
          className: s.utilityCard__image,
        })
      }
    </div>
    <div className={s.utilityCard__content}>
      {category && (<div className={s.utilityCard__category}>{category}</div>)}
      <div className={s.utilityCard__contentInner}>
        <h2 className={s('utilityCard__heading', { small: headingSize === 'small'})}>
          {link ? (<Link to={link} className={s.utilityCard__link}>{heading}</Link>) : heading}
        </h2>
        {map && (
          <div className={s.utilityCard__map}>{map}</div>
        )}
      </div>
    </div>
  </div>
);

UtilityCard.defaultProps = {
  headingSize: 'default',
};
