import React, { createRef, Component } from 'react';

import s from './CarouselProgress.scss';

interface IProps {
  count: number;
  activeSlide: number;
  hasRemainder: boolean;
  isMobile?: boolean;
}

// TODO: rewrite as function
export default class Carousel extends Component<IProps> {

  indicator = createRef<HTMLSpanElement>();
  container = createRef<HTMLDivElement>();

  state = {
    position: 0,
  };

  componentDidMount() {
    this.setPosition();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.activeSlide !== this.props.activeSlide) {
      this.setPosition();
    }
  }

  setPosition = () => {
    if (!this.container.current || !this.indicator.current) { return 0; }

    const { activeSlide, count, hasRemainder } = this.props;
    const done = hasRemainder ? (activeSlide + 1 === count) : (activeSlide + 1 >= count); // ugh this

    const containerWidth = this.container.current.offsetWidth;
    const indicatorWidth = this.indicator.current.offsetWidth;

    const position = (done ?
      ((containerWidth - indicatorWidth) / containerWidth) :

      activeSlide > 0 ?
        Math.min(Math.max(
        (((activeSlide / count) * (1 + (1 / count) )) -
        ((indicatorWidth / 2) / containerWidth)
        ),
      0), 100) : 0) * 100;

    this.setState({
      position,
    });
  }

  render() {
    return (
      <div className={s('carouselProgress')} ref={this.container}>
        <span
          className={s.carouselProgress__progress}
          style={{ transform: `translateX(${this.state.position}%)` }}
        >
          <span ref={this.indicator} className={s.carouselProgress__indicator} />
        </span>
      </div>
    );
  }
}
