import * as React from 'react';

import { Link } from 'components/link/Link';

import Arrow from 'assets/svg/icons/arrow.svg';

import s from './Button.scss';

interface IButtonProps {
  to?: string;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
  [key: string]: any;
  filled?: boolean;
  dark?: boolean;
  arrow?: boolean;
  download?: boolean;
  arrowDirection?: 'right' | 'down';
  borderColor?: 'light' | 'dark';
}

export const Button = ({
  to, children, className, disabled, filled, arrow, download, arrowDirection, dark, borderColor, ...rest }: IButtonProps) => {
  const passProps = { ...rest };
  const isLink = (typeof to !== 'undefined');
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(to || '');

  passProps.className = s(className, 'button', {
    disabled,
    filled,
    dark,
    transparent: !filled,
    noArrow: !arrow,
    arrowDown: arrowDirection === 'down',
    borderDark: (borderColor === 'dark' || dark),
  });

  const downloadSvg = (
  <svg width="22" height="22">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <path strokeLinecap="square" d="M1 15v6h20v-6" />
      <path d="M11 0v15"/>
      <path strokeLinecap="square" d="M5 9l6 6 6-6" />
    </g>
  </svg>

  );

  const content = (
    <span className={s.button__inner}>
      <span className={s.button__fill} />
      <span className={s.button__border} />
      <span className={s.button__text}>{children}</span>
      {download ?
        (<span className={s.button__icon}>{downloadSvg}</span>) :
        (<span className={s.button__icon}><Arrow /></span>)}
    </span>
  );

  if (isExternal) {
    return <a target="_blank" rel="noopener noreferrer" href={to} {...passProps}>{content}</a>;
  }

  if (isLink) {
    return <Link to={to || '#'} {...passProps}>{content}</Link>;
  }

  passProps.disabled = disabled;

  return <button {...passProps}>{content}</button>;
};

Button.defaultProps = {
  filled: true,
  arrow: true,
  arrowDirection: 'right',
};
