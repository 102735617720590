import * as React from 'react';

import { Link } from 'components/link/Link';
import Arrow from 'assets/svg/icons/arrow.svg';

import s from './CtaButton.scss';

interface ICtaButtonProps {
  to?: string;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
  [key: string]: any;
  arrow?: boolean;
}

export const CtaButton = ({
  to, children, className, arrow, disabled, visible, ...rest }: ICtaButtonProps) => {
  const passProps = { ...rest };
  const isLink = (typeof to !== 'undefined');
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(to || '');

  passProps.className = s(className, 'ctaButton', {
    disabled,
    visible,
  });

  const content = (
    <span className={s.ctaButton__inner}>
      <span className={s.ctaButton__text}>{children}</span>
      {arrow && <span className={s.ctaButton__icon}><Arrow /></span>}
    </span>
  );

  if (isExternal) {
    return <a target="_blank" rel="noopener noreferrer" href={to} {...passProps}>{content}</a>;
  }

  if (isLink) {
    return <Link to={to || '#'} {...passProps}>{content}</Link>;
  }

  passProps.disabled = disabled;

  return <button {...passProps}>{content}</button>;
};

CtaButton.defaultProps = {
  arrow: true,
};
