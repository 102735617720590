import React, { cloneElement } from 'react';

import { Button } from 'components/button/Button';
import { Container } from 'components/layout/Container';

import s from './ArticleCta.scss';

interface IProps {
  heading: string;
  link: string;
  linkText?: string;
  image?: React.ReactElement<any>;
}

export const ArticleCta = ({ heading, link, linkText, image }: IProps) => (
  <div className={s('articleCta')}>
    <Container>
      <div className={s.articleCta__inner}>
        <div className={s.articleCta__content}>
          {image && cloneElement(image, {
            className: s.articleCta__image,
            preserveRatio: false,
          })}
          <h2 className={s.articleCta__heading}>{heading}</h2>
          <Button to={link} aria-label={linkText}>{linkText}</Button>
        </div>
      </div>
    </Container>
  </div>
);

ArticleCta.defaultProps = {
  linkText: 'View list',
};
