// carousel with split black backgorund & clipped image transition
import React, { cloneElement, useState, useRef, useEffect, Children } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { isEmpty } from 'lodash';

import { useSwipe } from 'hooks/use-swipe';
import { CarouselButton } from 'components/carousel-button/CarouselButton';
import { ViewportEnter } from 'components/viewport-enter/ViewportEnter';

import s from './CtaCarousel.scss';

interface IProps {
  children: React.ReactNode;
}

export const CtaCarousel = ({ children }: IProps) => {

  const [activeSlide, setActiveSlide] = useState(0);
  const [inViewport, setInViewport] = useState(false);
  const [direction, setDirection] = useState(1);
  const carouselNode = useRef<HTMLDivElement>(null);

  const slides = Children.toArray(children);
  const slideCount = slides.length;
  const slide = slides[activeSlide] as React.ReactElement<any>;

  const next = activeSlide + 1 <= (slideCount - 1) ? activeSlide + 1 : 0;
  const prev = activeSlide - 1 >= 0 ? activeSlide - 1 : slideCount - 1;

  const swipeDirection = useSwipe(carouselNode);

  if (isEmpty(slides)) { return null; }

  const onPrev = () => {
    setDirection(0);
    setActiveSlide(prev);
  };

  const onNext = () => {
    setDirection(1);
    setActiveSlide(next);
  };

  useEffect(() => {
    if (!swipeDirection) { return; }

    if (swipeDirection === 'left') {
      onNext();
    } else if (swipeDirection === 'right') {
      onPrev();
    }
  }, [swipeDirection]);

  return (
    <ViewportEnter onEnter={() => { setInViewport(true); }}>
      <div className={s.ctaCarousel} ref={carouselNode}>
        <div className={s.ctaCarousel__inner}>
          <div className={s.ctaCarousel__background} />
          <div className={s.ctaCarousel__children}>
            {children}
          </div>
          <TransitionGroup>
            {cloneElement(slide, {
              direction,
              active: true,
              inViewport,
            })}
          </TransitionGroup>
        </div>

        {slideCount > 1 && (
          <div className={s.ctaCarousel__buttonWrap}>
            <CarouselButton
              direction="previous"
              onClick={onPrev}
            />
            <CarouselButton
              direction="next"
              onClick={onNext}
            />
          </div>
        )}
      </div>
    </ViewportEnter>
  );
};
