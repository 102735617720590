import React, { cloneElement, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Transition } from 'react-transition-group';
import gsap from 'gsap';

import { Button } from 'components/button/Button';

import s from './CtaItem.scss';

interface IProps {
  onStart?: () => void;
  onEnd?: () => void;
  direction?: 1 | 0;
  inViewport?: boolean;
  active?: boolean;
  kicker?: string | React.ReactElement<any>;
  heading: string;
  image?: React.ReactElement<any>;
  text?: string;
  link: string;
  linkText?: string;
}

export const CtaItem = ({
  onStart,
  onEnd,
  direction,
  inViewport,
  active, // abs position active guys
  kicker,
  heading,
  text,
  image,
  link,
  linkText,
  ...props
 }: IProps) => {

  if (!image) { return null; }

  const item = useRef<HTMLDivElement>(null);
  const imageNode = useRef<HTMLDivElement>(null);
  const contentNode = useRef<HTMLDivElement>(null);

  const ease = 'power2.inOut';
  const duration = 300;
  const path = direction === 1 ? {
    start: 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)',
    end: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
  } : {
    start: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)',
    end: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
  };

  const t = gsap.timeline();
  t.addLabel('start', 0);

  const onEnter = () => {
    t.set(imageNode.current!, { x: 0 })
      .fromTo(imageNode.current!, {
        webkitClipPath: path.start,
        clipPath: path.start,
      },
      {
        webkitClipPath: path.end,
        clipPath: path.end,
        ease,
        duration: (duration / 1000),
      }, 'start');

    t.to(contentNode.current!, (duration / 1000),
      { x: 0, autoAlpha: 1, ease }, 'start');
  };

  const onEntering = () => {
    if (onStart) { onStart(); }
    gsap.set(item.current, { zIndex: 2 });
    gsap.set(contentNode.current, { x: direction === 1 ? 70 : -50, autoAlpha: 0 });
  };

  const onEntered = () => {
    if (onEnd) { onEnd(); }
    gsap.set(item.current, { zIndex: 2 });
  };

  const onExiting = () => {
    gsap.set(item.current, { position: 'absolute', zIndex: 1 });

    t.fromTo(contentNode.current!, (duration / 1000),
    { autoAlpha: 1 },
    { autoAlpha: 0, ease }, 'start');
  };

  return (
    <Transition
      {...props}
      timeout={duration}
      onEnter={onEnter}
      onEntering={onEntering}
      onEntered={onEntered}
      onExiting={onExiting}
      unmountOnExit={false}
    >
        <div className={s('ctaItem', { active })} ref={item}>
            <div className={s.ctaItem__container}>
              <div className={s.ctaItem__inner}>
                <div className={s.ctaItem__row}>
                  <div className={s.ctaItem__imageWrap}>
                    <div className={s.ctaItem__imageDimensions}>
                      <div className={s.ctaItem__imageClip} ref={imageNode}>
                        {cloneElement(image, {
                          className: s.ctaItem__image,
                          preserveRatio: false,
                        })}
                      </div>
                    </div>
                  </div>

                  <div className={s.ctaItem__content}>
                    <div className={s.ctaItem__contentInner} ref={contentNode}>
                      {kicker && (<div className={s.ctaItem__kicker}>{kicker}</div>)}
                      <h1 className={s.ctaItem__heading}>{heading}</h1>
                      <p className={s.ctaItem__text}>{text}</p>
                      <div className={s.ctaItem__button}>
                        <FormattedMessage id="explore">
                          {(defaultLinkText) =>
                            <Button filled={false} to={link} aria-label={linkText || defaultLinkText}>{linkText || defaultLinkText}</Button>
                          }
                        </FormattedMessage>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
    </Transition>
  );
};
