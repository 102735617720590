import React, { useEffect, useState, useContext, useRef, Children } from 'react';
import gsap from 'gsap';
import { useDrag } from 'react-use-gesture';

import { useSwipe } from 'hooks/use-swipe';
import { useResize } from 'hooks/use-resize';
import { UIContext } from 'context/ui';

import { Container } from 'components/layout/Container';
import { ViewportEnter } from 'components/viewport-enter/ViewportEnter';
import { CarouselButton } from 'components/carousel-button/CarouselButton';

import CarouselProgress from './CarouselProgress';

import s from './Carousel.scss';

interface IProps {
  heading?: string | React.ReactElement<any>;
  rowHeading?: string | React.ReactElement<any>;
  description?: string;
  children: React.ReactNode;
  slideCount: number;
}

export const Carousel = ({ heading, description, children, slideCount, rowHeading}: IProps) => {

  const track = useRef<HTMLOListElement>(null);
  const overflow = useRef<HTMLDivElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [canMove, setCanMove] = useState(true);
  const [canMoveForwards, setCanMoveForwards] = useState(true);
  const [position, setPosition] = useState(0);
  const resize = useResize();
  const { isMobile } = useContext<any>(UIContext);

  const childCount = Children.count(children);
  const canMoveBackwards = activeSlide > 0;
  const V_THRESHOLD = 0.1;

  const count = isMobile ? childCount : Math.ceil(childCount / slideCount);

  // get size of items (assumes even size)
  const getSize = () => {
    const itemCount = isMobile ? 1 : slideCount;

    return (
      Math.ceil(
        track.current!.scrollWidth / (childCount / itemCount),
      ) || 0
    );
  };

  const bind = useDrag(({ last, direction: [xDir], vxvy: [vx, vy] }) => {
    if (last) {
      const dir = xDir < 0 ? 'left' : 'right'; // Direction should either point left or right

      if (vx < -V_THRESHOLD && dir === 'left') {
        onNext();
      }

      if (vx > V_THRESHOLD && dir === 'right') {
        onPrevious();
      }
    }
  });

  // move track
  const move = () => {
    setPosition(activeSlide * getSize());
  };

  useEffect(() => {
    onResize();
  }, [resize.sizes.width]);

  // reset to first slide on resize
  const onResize = () => {

    if (position === 0 && activeSlide === 0) { return; }

    setActiveSlide(0);
    setPosition(0);

    onUpdate();
  };

  // transition end on track move to check if carousel can move
  useEffect(() => {
    if (!track.current) { return; }
    track.current.addEventListener('transitionend', (e) => onUpdate(e));

    return () => {
      if (!track.current) { return; }
      track.current.removeEventListener('transitionend', onUpdate);
    };
  }, [activeSlide]);

  // listen to activeSlide updates
  useEffect(() => {
    move();
  }, [activeSlide]);

  // swipe update
  const swipeDirection = useSwipe(track);
  useEffect(() => {
    if (!swipeDirection) { return; }

    if (swipeDirection === 'left') {
      onNext();
    } else if (swipeDirection === 'right') {
      onPrevious();
    }
  }, [swipeDirection]);

  // stagger in on viewport enter
  const onEnter = () => {
    if (slideCount <= 1) { return; }

    const t = gsap.timeline();
    const childNodes = track.current!.childNodes;
    // only animate first potentially visible ones
    const items = Array.from(childNodes).slice(0, slideCount);
    t.fromTo(items, 0.75, {
      x: 40,
      autoAlpha: 0,
    },
    {
      x: 0, autoAlpha: 1,
      stagger: 0.15,
    });
  };

  const onNext = () => {
    if (!canMove || !canMoveForwards) {
      return;
    }

    setActiveSlide(activeSlide + 1);
    setCanMove(false);
  };

  const onPrevious = () => {
    if (activeSlide === 0 || !canMove) {
      return;
    }

    setActiveSlide(activeSlide - 1);
    setCanMove(false);
  };

  // can carousel move?
  const onUpdate = (e?: TransitionEvent) => {
    if (!track.current || !wrapper.current || !canMove) { return; }
    if (e) {
      e.stopPropagation();
    }
    setCanMove(false);

    const wrapperRect = wrapper.current.getBoundingClientRect();

    const last = (track.current.lastChild as HTMLDivElement).getBoundingClientRect();

    setCanMoveForwards(wrapperRect.x + wrapperRect.width < last.x + last.width);
    setCanMove(true);
  };

  return (
  <ViewportEnter onEnter={onEnter}>
    <div className={s('carousel', `count${slideCount}`, { hasRowHeading: rowHeading })}>
      {(heading || description) && (
        <Container>
          <div className={s.carousel__lead}>
            {heading && (<h1 className={s.carousel__heading}>{heading}</h1>)}
            {description && (<div className={s.carousel__description}>{description}</div>)}
          </div>
        </Container>
      )}

        <div className={s.carousel__wrapper} ref={wrapper}>

        {rowHeading && (
        <div className={s.carousel__rowHeading}>
          <h1 className={s.carousel__heading}>{rowHeading}</h1>
        </div>)}

        <Container>
          <div className={s.carousel__overflow} ref={overflow} {...bind()}>
            <ol
              className={s.carousel__inner}
              ref={track}
              style={{ transform: `translateX(-${position}px)`}}
            >
              {Children.map(children, (child, i) => {
                if (child === null) { return null; }

                return (
                  <li className={s.carousel__item} key={`${s.carousel__item}-${i}`}>
                    {React.cloneElement(child as React.ReactElement<any>, {
                      headingSize: rowHeading ? 'small' : 'default',
                    })}
                  </li>
                );
              })}
            </ol>
          </div>
        </Container>

        { slideCount < childCount && (
          <div className={s.carousel__buttonWrap}>
            <div className={s('carousel__button', 'carousel__buttonNext', { disabled: !canMoveForwards })}>
              <CarouselButton
                direction="next"
                disabled={!canMoveForwards}
                onClick={onNext}
                className={s.carousel__buttonEl}
              />
            </div>

            <div className={s('carousel__button', 'carousel__buttonPrev', { disabled: !canMoveBackwards })}>
              <CarouselButton
                direction="previous"
                disabled={!canMoveBackwards}
                onClick={onPrevious}
              />
            </div>
          </div>
        )}

        </div>

      {(!rowHeading && slideCount <= count) && (
        <Container>
          <CarouselProgress
            activeSlide={activeSlide}
            count={count}
            hasRemainder={count % slideCount > 0}
          />
        </Container>
      )}
    </div>
  </ViewportEnter>
  );

};

Carousel.defaultProps = {
  children: undefined,
  slideCount: 3,
};
