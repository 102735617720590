import * as React from 'react';

import _throttle from 'lodash/throttle';

export const useSwipe = (ref: React.RefObject<any>, threshold = 50) => {
  const [swipeComplete, setSwipeComplete] = React.useState(false);
  const [originalX, setXPosition] = React.useState(0);
  const [swipeDirection, setDirection] = React.useState(null);

  const onTouchStart = (e: React.TouchEvent) => {
    const touch = e.touches[0];

    setXPosition(touch.pageX);
    setSwipeComplete(false);
  };

  const onTouchEnd = (e: React.TouchEvent) => {
    if (swipeComplete) { return; }

    const touch = e.changedTouches[0];
    const totalX = (touch && touch.pageX - originalX);

    if (totalX > threshold) {
      setDirection('right' as any);
      setSwipeComplete(true);
    } else if (totalX < -(threshold)) {
      setDirection('left' as any);
      setSwipeComplete(true);
    }
  };

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.addEventListener('touchstart', onTouchStart, { passive: true });
    ref.current.addEventListener('touchend', onTouchEnd, { passive: true });

    return () => {
      if (!ref.current) {
        return;
      }
      ref.current.removeEventListener('touchstart', onTouchStart);
      ref.current.removeEventListener('touchend', onTouchEnd);
    };
  });

  if (!swipeComplete) { return; }

  return swipeDirection;
};
